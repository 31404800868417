import { createRouter, createWebHistory } from "vue-router";
import HomeView from "../views/HomeView.vue";
// import Main from "../views/Main.vue";
import locale from "@/locale";
import { getToken } from "@/libs/utils";
import { useAppStore } from "@/store";
import { Message } from "@arco-design/web-vue";

const routes = [
  {
    path: "/",
    redirect: "/home",
    component: HomeView,
    children: [
      {
        path: "/home",
        name: "home",
        meta: {
          title: "title.home",
        },
        component: () => import("@/views/home/index.vue"),
      },
      
      {
        path: "/about",
        name: "about",
        meta: {
          title: "title.about",
        },
        component: () => import("@/views/about/index.vue"),
      },
      {
        path: "/episodes/:id",
        name: "episodes",
        meta: {
          title: "title.play",
        },
        component: () => import("@/views/episodes/index.vue"),
      },
      {
        path: "/play/:id",
        name: "play",
        meta: {
          title: "title.play",
        },
        component: () => import("@/views/play/index.vue"),
      },
      {
        path: "/privacy",
        name: "privacy",
        meta: {
          title: "title.privacy",
        },
        component: () => import("@/views/privacy/index.vue"),
      },
      {
        path: "/agreement",
        name: "agreement",
        meta: {
          title: "title.agreement",
        },
        component: () => import("@/views/privacy/index.vue"),
      },
      {
        path: "/search",
        name: "search",
        meta: {
          title: "Search",
        },
        component: () => import("@/views/search/index.vue"),
      },
      {
        path: "/theater/:id",
        name: "theater",
        meta: {
          title: "Theater",
        },
        component: () => import("@/views/theater/index.vue"),
      },
    ],
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  base: '/website/'
});

// 

router.beforeEach(async (to, form, next) => {
  if (to.meta && to.meta.title) {
    document.title = locale.global.t(to.meta.title);
  }
  const appStore = useAppStore()

  if(!getToken()){
    await appStore.handleLogin()
  } 

  // if(to.path==='/play'&&!to.params.id){
  //   Message.error('短剧不存在')
  //   next({
  //     path: '/home'
  //   })
  // }
  next();
});

export default router;
