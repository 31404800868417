import localeSettings from "./zh-CN/settings";

export default {
  "menu.home": "首页",
  "menu.solution": "解决方案",
  "menu.about": "App",
  "menu.server.monitor": "实时监控-服务端",
  "menu.list": "列表页",
  "menu.result": "结果页",
  "menu.exception": "异常页",
  "menu.form": "表单页",
  "menu.profile": "详情页",
  "menu.visualization": "数据可视化",
  "menu.user": "个人中心",
  "menu.arcoWebsite": "Arco Design",
  "menu.faq": "常见问题",
  "navbar.docs": "文档中心",
  "navbar.action.locale": "切换为中文",

  "common.neterror":"网络错误",

  "title.home": "首页",
  "title.about": "App",
  "title.recommend": "推荐",
  "title.must": "必看好剧",
  "title.more": "更多",
  "title.trending": "当前热播",
  "title.wonderful": "精彩剧集",
  "title.play": '视频播放',
  "title.agreement": '用户协议',
  "title.privacy": '隐私政策',
  "header.search": "搜索...",


  "main.episode.list": "剧集列表",
  "main.recommend": "猜你喜欢",
  "main.episode": "剧集",
  "main.episodes": "集",
  "main.download": "下载",
  "main.download.tip": "本集需要下载App观看",
  "main.no.episode": "无剧集",
  "main.loading": "加载中",
  "main.play": "立即播放",
  "main.desc": "剧情简介",
  "main.collect": "收藏",
  "main.search.result": "搜索结果",

  "footer.about": '关于我们',
  "footer.contact": '联系我们',
  "footer.join": '加入我们',
  "footer.more": '更多',
  "footer.moreTV": '更多剧集',
  "footer.service": '服务协议',
  "footer.policy": '隐私协议',


  "about.title":'看片，影视，动人情节',
  "about.main.1":'在赶路、小憩片刻或者忙碌一天后想要放松一下吗？',
  "about.main.2":'快来体验我们的APP呀！',
  "about.main.3":'热门大片看个够，每时每刻乐无忧！',
  "about.main.4":'从小众文艺片到口碑爆棚的热门剧集，我们为您提供丰富的选项和不断更新的资源,',
  "about.main.5":'肯定有吸引您的精彩内容！',
  ...localeSettings,
};
