export default {
  "settings.title": "Settings",
  "settings.themeColor": "Theme Color",
  "settings.content": "Content Setting",
  "settings.search": "Search",
  "settings.language": "Language",
  "settings.navbar": "Navbar",
  "settings.menuWidth": "Menu Width (px)",
  "settings.navbar.theme.toLight": "Click to use light mode",
  "settings.navbar.theme.toDark": "Click to use dark mode",
  "settings.navbar.screen.toFull": "Click to switch to full screen mode",
  "settings.navbar.screen.toExit": "Click to exit the full screen mode",
  "settings.navbar.alerts": "alerts",
  "settings.menu": "Menu",
  "settings.topMenu": "Top Menu",
  "settings.tabBar": "Tab Bar",
  "settings.footer": "Footer",
  "settings.otherSettings": "Other Settings",
  "settings.colorWeak": "Color Weak",
  "settings.alertContent":
    'After the configuration is only temporarily effective, if you want to really affect the project, click the "Copy Settings" button below and replace the configuration in settings.json.',
  "settings.copySettings": "Copy Settings",
  "settings.copySettings.message":
    "Copy succeeded, please paste to file src/settings.json.",
  "settings.close": "Close",
  "settings.color.tooltip":
    "10 gradient colors generated according to the theme color",
  "settings.menuFromServer": "Menu From Server",
};
