import { login } from "@/api/user";
import { setToken } from "@/libs/utils";
import { createPinia, defineStore } from "pinia";

const useAppStore = defineStore("app", {
  state: () => ({
    theme: "dark",
    user: {}
  }),
  actions: {
    toggleTheme(dark) {
      if (dark) {
        this.theme = "dark";
        document.body.setAttribute("arco-theme", "dark");
      } else {
        this.theme = "light";
        document.body.removeAttribute("arco-theme");
      }
    },
    async handleLogin(userData={userName:'webUser',password:'123456'}){
      return new Promise((resolve, reject) => {
        login({...userData}).then(({data})=>{
          setToken(data.token)
          this.user = data
          resolve()
        }).catch(e=>{
          reject(e)
        })
      })
    }
  },
});

export { useAppStore };
export const pinia = createPinia();
