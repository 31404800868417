import localeSettings from "./en-US/settings";

export default {
  "menu.home": "Home",
  "menu.solution": "Solution",
  "menu.about": "App",
  "menu.server.monitor": "Monitor-Server",
  "menu.list": "List",
  "menu.result": "Result",
  "menu.exception": "Exception",
  "menu.form": "Form",
  "menu.profile": "Profile",
  "menu.visualization": "Data Visualization",
  "menu.user": "User Center",
  "menu.arcoWebsite": "Arco Design",
  "menu.faq": "FAQ",
  "navbar.docs": "Docs",
  "navbar.action.locale": "Switch to English",

  "title.home": "HOME",
  "title.about": "APP",
  "title.recommend": "For You",
  "title.must": "Must-sees",
  "title.more": "More",
  "title.wonderful": "Hidden Gems",
  "title.trending": "Trending",
  "title.play": "Play",
  "title.agreement": 'Team of Service',
  "title.privacy": 'Privacy Policy',
  "header.search": "Search",
  "common.neterror":"Network Error",

  "main.episode.list": "Episode List",
  "main.episode": "Episode",
  "main.episodes": "Episodes",
  "main.download": "DownLoad",
  "main.recommend": "Recommended for you",
  "main.download.tip": "This episode needs to be downloaded to watch",
  "main.no.episode": "no episode",
  "main.loading": "Loading",
  "main.play": "Play Now",
  "main.desc": "Plot synopsis",
  "main.collect": "Collect",
  "main.search.result": "Search Result",


  "footer.about": 'About',
  "footer.contact": 'Contact Us',
  "footer.join": 'Join Us',
  "footer.more": 'More',
  "footer.moreTV": 'More TV series',
  "footer.service": 'Team of Service',
  "footer.policy": 'Privacy Policy',

  "about.title":'Movies, Films, and Fascinating Plots',
  "about.main.1":'Do you want to relax while on the road, taking a short break, or after a busy day?',
  "about.main.2":'Come and experience our app!',
  "about.main.3":"There are plenty of blockbuster movies for you to watch to your heart's content, and you can enjoy every moment!",
  "about.main.4":'From niche art - house films to highly - acclaimed popular dramas, we offer you a rich selection and continuously updated resources.',
  "about.main.5":'There must be something wonderful to attract you!',
  ...localeSettings,
};
