<template>
    <div class="main-header">
        <a-menu
          mode="horizontal"
          theme="dark"
          v-model:selected-keys="active"
          @menu-item-click="handleGoPath"
        >
          <a-menu-item
            key="0"
            :style="{ padding: 0, margin: '0' }"
            disabled
          >
            <div
              :style="{
                width: '80px',
                height: '30px',
                borderRadius: '2px',
                cursor: 'text',
              }"
            >
              <img :src="logo" v-lazy="logo" style="width: 100%" />
            </div>
          </a-menu-item>
          <a-menu-item key="/home">{{ $t("menu.home") }}</a-menu-item>
          <a-menu-item key="/about">{{ $t("menu.about") }}</a-menu-item>
        </a-menu>
        <div style="display: flex; align-items: center">
          <a-input :placeholder="$t('header.search')" v-model="searchData" allow-clear @press-enter="handleGoPath('/search',{playName:searchData,t:moment().valueOf()})" style="border-radius: 8px;width:576px;padding:5.5px 0;" >
            <template #prefix>
              <icon-search style="padding-left: 18px;" />
            </template>
          </a-input>
        </div>
            <a-select
              :bordered="false"
              style="width: 270px;"
              :model-value="currentLocale"
              @change="handleChangeLocale"
            >
              <a-option value="zh-CN">简体中文</a-option>
              <a-option value="en-US">English-US</a-option>
            </a-select>
      </div>
</template>

<script setup>
import { ref, watch } from 'vue';
import logo from '@/assets/images/logo.png'
import { useRoute, useRouter } from 'vue-router';
import moment from 'moment';
import locale from "@/locale";
import useLocale from '@/hooks/locale'

const route = useRoute()
const { changeLocale, currentLocale } = useLocale();
const router = useRouter()
const active = ref([route.path]);
const searchData = ref(route.query.playName)
watch(()=>route.path,()=>{
//   selectMenu.value = route.path
  active.value = [route.path];
})
const handleChangeLocale = (data) => {
  changeLocale(data);
  document.title = locale.global.t(route.meta.title);
};
const handleGoPath = (path,query) => {
//   visible.value = false
  router.push({path,query});
  
};
</script>

<style lang="less" scoped>
@import url('@/views/css/home.less');
</style>