import { createApp } from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import "@arco-design/web-vue/dist/arco.css";
import ArcoVue from "@arco-design/web-vue";
import locale from "./locale";
import { pinia } from "./store";
import "vant/lib/index.css";
import "lib-flexible/flexible.js";
import ArcoVueIcon from "@arco-design/web-vue/es/icon";
import { Lazyload } from 'vant';

const app = createApp(App);
app.use(ArcoVueIcon);
app.use(Lazyload);

app.use(ArcoVue);

app.use(pinia);

app.use(locale);

app.use(router);

app.mount("#app");
