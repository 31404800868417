<template>
  <a-config-provider :locale="locale" :data-theme="'dark'">
    <!-- <a-scrollbar style="height: 100vh; overflow: auto"> -->
    <ConfigProvider theme="dark">
      <router-view />
    </ConfigProvider>
    
    <!-- </a-scrollbar> -->
  </a-config-provider>
</template>

<script setup>
import enUS from "@arco-design/web-vue/es/locale/lang/en-us";
import zhCN from "@arco-design/web-vue/es/locale/lang/zh-cn";
import { ConfigProvider } from "vant";
import useLocale from "@/hooks/locale";
import { computed } from "vue";
import { Locale } from 'vant';
import vanEnUs from 'vant/es/locale/lang/en-US';
import vanZhCn from 'vant/es/locale/lang/zh-CN';

const { currentLocale } = useLocale();

document.documentElement.setAttribute("data-theme", "dark");
document.body.setAttribute("arco-theme", "dark");

// const toggleTheme = useToggle(isDark);
const locale = computed(() => {
  switch (currentLocale.value) {
    case "zh-CN":
      Locale.use('zh-Ch', vanZhCn);
      return zhCN;
    case "en-US":
      Locale.use('en-US', vanEnUs);
      return enUS;
    default:
      Locale.use('en-US', vanEnUs);
      return enUS;
  }
});

</script>

<style lang="less">
@media screen and (min-width: 765px) {
  @import url("./views/css/main.less");
}

@import url("./views/css/theme.less");

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: relative;
}
.arco-breadcrumb-item a:hover{
  background-color: transparent !important;
  color:#ff3f5f !important;
}

::-webkit-scrollbar {
  background-color: #f5f5f5;
  width: 10px;
}
/*定义滚动条轨道 内阴影+圆角*/
::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px 6px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  background-color: #f5f5f5;
}
/*定义滑块 内阴影+圆角*/
::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px 6px rgba(0, 0, 0, 0.3);
  background-color: #ddd;
}
</style>
