import axios from 'axios'
import { getToken } from './utils'
class HttpRequest {
  constructor(baseUrl = baseURL) {
    this.baseUrl = baseUrl
    this.queue = {}
  }
  getInsideConfig() {
    const config = {
      baseURL: this.baseUrl,
      headers: {
        //
      }
    }
    return config
  }
  destroy(url) {
    delete this.queue[url]
    if (!Object.keys(this.queue).length) {
      // Spin.hide()
    }
  }
  interceptors(instance, url) {
    // 请求拦截
    instance.interceptors.request.use(
      (config) => {
        // 添加全局的loading...
        if (!Object.keys(this.queue).length) {
          // Spin.show() // 不建议开启，因为界面不友好
        }
        this.queue[url] = true
        if (!config.noToken) {
          config.headers['token'] = getToken()
          config.headers['lang'] = localStorage.getItem("arco-locale")||'en-US';

        }
        return config
      },
      (error) => {
        return Promise.reject(error)
      }
    )
    // 响应拦截
    instance.interceptors.response.use(
      (res) => {
        this.destroy(url)
        const { data } = res

        if (res.config.skipIntercept) return res
        if (data.code === 200) { return data } else {

          return Promise.reject(data)
        }
      },
      (error) => {
        this.destroy(url)
        let errorInfo = error.response
        const { status } = errorInfo
        if (!errorInfo) {
          const {
            request: { statusText, status },
            config
          } = JSON.parse(JSON.stringify(error))
          errorInfo = {
            statusText,
            status,
            request: { responseURL: config.url }
          }
        }
        if(status === 403||status===401){
          localStorage.removeItem('token')
        }
        // if (status === 401) {
        //   Message.error('登录状态过期，请重新登录')
        //   store.dispatch('handleLogOut', true).then(r => {
        //     router.push({
        //       name:'login'
        //     })
        //   })
        // }
        // addErrorLog(errorInfo)
        return Promise.reject(error)
      }
    )
  }
  request(options) {
    const instance = axios.create()
    options = Object.assign(this.getInsideConfig(), options)
    this.interceptors(instance, options.url)
    return instance(options)
  }
}

const aioxs = new HttpRequest(process.env.NODE_ENV === 'development'
    ? '/api/'
    : '/')
export default aioxs
