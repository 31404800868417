<template>
  <a-layout style="min-height: 100vh;position:relative;">
    <a-layout-header class="a-head">
      <PcHeader />
      <div v-if="route.path.includes('home')" class="mobile-header">
        <!-- <div style="height: 1.4rem; background: #ffffffe6"></div> -->
        <div v-if="!searchModal" style="display: flex; justify-content: space-between">
          <div style="width: 100%">
            <span v-for="(item, index) in menu" :key="index" :class="selectMenu === item.key
                ? 'menu-checked mobile-menu'
                : 'mobile-menu'
              " :style="{
                color: selectMenu === item.key ? '#ff3f5f' : '#ffffffe6',
                fontWeight: selectMenu === item.key ? 'bold' : 'normal',
                display: item.hideInMenu ? 'none' : 'inline-block'
              }" @click="selectMenu = item.key; handleGoPath(item.key)">{{ $t(item.value) }}</span>
          </div>
          <div style="display: flex; align-items: center">
            <icon-search :size="30" style="color: #ffffffe6" @click="searchModal = true" />
            <a-select :bordered="false" :model-value="currentLocale" @change="handleChangeLocale">
              <a-option value="zh-CN">简体中文</a-option>
              <a-option value="en-US">English-US</a-option>
            </a-select>
          </div>
        </div>
        <div v-else>
          <Form action="/">
            <Search v-model="searchData" placeholder="Search..." @search="onSearch" >
              <template #right-icon>
                <icon-undo @click="searchModal=false" />
              </template>
            </Search>
          </Form>
        </div>
      </div>
      <div v-else-if="route.path.includes('search')" style="" class="mobile-header search-mobile-header">
        <div style="display:flex;align-items: center;">
            <icon-menu :size="20" @click="visible = true" />
          </div>
        <Form action="/" style="width: 100%;">
            <Search v-model="searchData" placeholder="Search..." @search="onSearch" style="padding-right: 0;" >
            </Search>
          </Form>
      </div>
      <div class="mobile-header" v-else>
        <!-- <div style="height: 1.4rem; background: #ffffffe6"></div> -->
        <div style="display: flex; justify-content: space-between;padding: 0 0.2rem;">
          <div style="display:flex;align-items: center;">
            <icon-menu :size="20" @click="visible = true" />
          </div>
          <div style="width: 100%;padding: 0.4rem 0;text-align: center;font-size: 0.4rem;">
            Logo
          </div>
          <div style="display: flex; align-items: center">
            <!-- <icon-search :size="30" style="color: #ffffffe6" />
            <a-select
              :bordered="false"
              :model-value="currentLocale"
              @change="handleChangeLocale"
            >
              <a-option value="zh-CN">简体中文</a-option>
              <a-option value="en-US">English-US</a-option>
            </a-select> -->
          </div>
        </div>
      </div>
    </a-layout-header>
    <a-layout-content class="a-content" ref="main">
      <Transition name="movaR">
        <KeepAlive>
          <RouterView />
        </KeepAlive>
      </Transition>
    </a-layout-content>
    <a-layout-footer class="a-footer">
      <Footer />
    </a-layout-footer>

    <a-back-top class="backtop" :target-container="main">
      <img style="cursor: pointer;width:78px;height:78px;" src="@/assets/icons/backtop.png" />
    </a-back-top>
    <a-drawer v-model:visible="visible" placement="left" :footer="false" :closable="false" :mask-closable="true">
      <template #header>
        <div style="display:flex;justify-content:flex-end;width:100%">
          <icon-close @click="visible = false" />
        </div>
      </template>
      <div style="width: 100%">
        <div v-for="(item, index) in menu" :key="index" :class="selectMenu === item.key
            ? 'menu-checked mobile-menu'
            : 'mobile-menu'
          " :style="{
                color: selectMenu === item.key ? '#ff3f5f' : '#ffffffe6',
                fontWeight: selectMenu === item.key ? 'bold' : 'normal',
              }" @click="handleGoPath(item.key)">{{ $t(item.value) }}</div>
      </div>
    </a-drawer>
  </a-layout>
</template>

<script setup>
import { ref, watch } from "vue";
import useLocale from "@/hooks/locale";
import PcHeader from '@/components/header'
import { Search, Form } from 'vant'
import locale from "@/locale";
import { useRoute, useRouter } from "vue-router";
import Footer from "./components/footer.vue";
import moment from "moment";
const searchModal = ref(false)
const searchData = ref('')
const route = useRoute();
const main = ref(null)
const router = useRouter();
const visible = ref(false)

const menu = ref([
  {
    key: "/home",
    value: "title.recommend",
  },
  {
    key: "/about",
    hideInMenu: true,
    value: "title.about",
  },
]);
const selectMenu = ref(route.path);
const { changeLocale, currentLocale } = useLocale();

const active = ref([route.path]);

const onSearch = ()=>{
  router.push({
    path: '/search',
    query: {
      playName: searchData.value,
      t: moment().valueOf()
    }
  })
}
const onCancel = ()=>{

}
const handleChangeLocale = (data) => {
  changeLocale(data);
  document.title = locale.global.t(route.meta.title);
};
watch(() => route.path, () => {
  selectMenu.value = route.path
  active.value = [route.path];
})
const handleGoPath = (path, query) => {
  router.push({ path, query });
  visible.value = false
  active.value = [path];
};

</script>

<style lang="less" scoped>
@import url('@/views/css/home.less');
</style>
