const TOKEN_KEY = "token";
export const getToken = () => {
  const token = localStorage.getItem(TOKEN_KEY);
  if (token) return token;
  else return false;
};

export const setToken = (token) => {
  localStorage.setItem(TOKEN_KEY, token);
};

export const numClac = (num)=>{
  const length = String(num).length
  if(length<4){
    return num
  }else if(length<6){
    return (num/1000).toFixed(1)+'k'
  }else if(length<7){
    return (num/10000).toFixed(1)+'w'
  }else if(length<9){
    return (num/1000000).toFixed(1)+'m'
  }else {
    return '99m+'
  }
}