<template>
    <div class="main-footer">
          <div>
            <div class="main-footer-header">
              {{ $t('footer.about') }}
            </div>
            <a class="text-box" href="/website/agreement">{{ $t('footer.service') }}</a>
            <a class="text-box" href="/website/privacy">{{ $t('footer.policy') }}</a>
          </div>
          <div>
            <div class="main-footer-header">
              {{ $t('footer.contact') }}
            </div>
            <a class="text-box" href="">Email：example@xx.com</a>
          </div>
          <div>
            <div class="main-footer-header">
              {{ $t('footer.join') }}
            </div>
            <a class="text-box" href="">Email：example@xx.com</a>
          </div>
          <div>
            <div class="main-footer-header">
              {{ $t('footer.more') }}
            </div>
            <a class="text-box" href="/website/search?playName=">{{ $t('footer.moreTV') }}</a>
          </div>
        </div>
</template>
<script setup>

</script>

<style scoped lang="less">
@import url("@/views/css/home.less");
</style>